var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-face-reco" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c("label", [_vm._v("人脸识别记录")]),
        _c(
          "div",
          [
            !_vm.type
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.search },
                  },
                  [_vm._v("高级搜索")]
                )
              : _vm._e(),
            _c(
              "el-radio-group",
              {
                staticClass: "label",
                attrs: { size: "small" },
                on: { change: _vm.typeChange },
                model: {
                  value: _vm.type,
                  callback: function ($$v) {
                    _vm.type = $$v
                  },
                  expression: "type",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: 1 } }, [
                  _vm._v("实时"),
                ]),
                _c("el-radio-button", { attrs: { label: 0 } }, [
                  _vm._v("分页"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("el-table-column", {
                attrs: { label: "采集照片", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: scope.row.captureUrl, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "相似度", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.similarity) + "% ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "比对照片", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: scope.row.photo, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "警示" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == -1
                          ? _c("span", [_vm._v("不在库中")])
                          : _vm._e(),
                        scope.row.status == 0
                          ? _c("span", [_vm._v("正常")])
                          : _vm._e(),
                        scope.row.status == 1
                          ? _c("span", [_vm._v("警示")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("VIP")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "recognTime", label: "识别时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { label: "身份信息", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "post-card" }, [
                          _c("div", [
                            _c("p", [_vm._v(_vm._s(scope.row.afr.idcard))]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "afrLibName", label: "所属库" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createdName", label: "创建用户" },
              }),
              !_vm.type
                ? _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("i", {
                                staticClass: "el-icon-delete",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteList(scope.row)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1700652357
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.type
        ? _c("common-pagination", {
            on: { curPageChange: _vm.handleCurPageChange },
            model: {
              value: _vm.pageOptions,
              callback: function ($$v) {
                _vm.pageOptions = $$v
              },
              expression: "pageOptions",
            },
          })
        : _vm._e(),
      _c("face-search", {
        on: { handleSearch: _vm.handleSearch },
        model: {
          value: _vm.searchDialogVisible,
          callback: function ($$v) {
            _vm.searchDialogVisible = $$v
          },
          expression: "searchDialogVisible",
        },
      }),
      _c("common-delete", {
        on: { handleDelete: _vm.handleDelete },
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
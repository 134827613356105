<template>
  <div class="js-common-wrap js-face-reco">
    <div class="js-common-head">
      <label>人脸识别记录</label>
      <div>
        <el-button v-if="!type" type="primary" size="small" @click="search"
          >高级搜索</el-button
        >
        <el-radio-group
          class="label"
          v-model="type"
          size="small"
          @change="typeChange"
        >
          <el-radio-button :label="1">实时</el-radio-button>
          <el-radio-button :label="0">分页</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="js-common-content">
      <el-table
        class="js-table"
        :data="tableData"
        height="100%"
        empty-text="暂无数据"
        @row-click="handleRowClick"
      >
        <el-table-column label="采集照片" width="180">
          <template slot-scope="scope">
            <img class="img" :src="scope.row.captureUrl" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="相似度" width="120">
          <template slot-scope="scope"> {{ scope.row.similarity }}% </template>
        </el-table-column>
        <el-table-column label="比对照片" width="180">
          <template slot-scope="scope">
            <img class="img" :src="scope.row.photo" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="警示">
          <template slot-scope="scope">
            <span v-if="scope.row.status == -1">不在库中</span>
            <span v-if="scope.row.status == 0">正常</span>
            <span v-if="scope.row.status == 1">警示</span>
            <span v-if="scope.row.status == 2">VIP</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="recognTime"
          label="识别时间"
          width="160"
        ></el-table-column>
        <el-table-column label="身份信息" width="200">
          <template slot-scope="scope">
            <div class="post-card">
              <div>
                <!-- <p>{{scope.row.afr.name}}</p> -->
                <p>{{ scope.row.afr.idcard }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="afrLibName" label="所属库"></el-table-column>
        <el-table-column prop="createdName" label="创建用户"></el-table-column>
        <el-table-column v-if="!type" label="操作">
          <template slot-scope="scope">
            <i class="el-icon-delete" @click.stop="deleteList(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination
      v-if="!type"
      v-model="pageOptions"
      @curPageChange="handleCurPageChange"
    ></common-pagination>
    <face-search
      v-model="searchDialogVisible"
      @handleSearch="handleSearch"
    ></face-search>
    <common-delete
      v-model="deleteDialogVisible"
      @handleDelete="handleDelete"
    ></common-delete>
  </div>
</template>
<script>
import CommonPagination from "@/components/CommonPagination";
import FaceSearch from "@/components/FaceSearch";
import CommonDelete from "@/components/CommonDelete";
import { mapGetters } from "vuex";
import { RTMClient, RTMChannelService } from "@/assets/js/RTMClient";
export default {
  name: "",
  components: {
    CommonPagination,
    FaceSearch,
    CommonDelete,
  },
  data() {
    return {
      type: 1,
      tableData: [],
      searchDialogVisible: false,
      deleteDialogVisible: false,
      options: {
        startDate: "",
        endDate: "",
        name: "",
        createdName: "",
        idcard: "",
        status: "",
      },
      pageOptions: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      currentItem: "",
      rtmCommonClient: "",
      rtmCommonChannel: "",
      timerChannel: "",
      realInfo: "",
      channelMsg: {
        url: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  mounted() {
    this.getList();
    this.rtmClient();
  },
  beforeDestroy() {
    clearInterval(this.timerChannel);
  },
  methods: {
    // 创建rtm
    rtmClient() {
      this.initialRoom();
    },
    initialRoom() {
      // rtmCommonChannel为异步第二步，它初始化完成后，2个对象就都有了，再执行事件绑定
      if (window?.GLOBAL?.state === "ready") {
        this.CommonRtcClient = window.GLOBAL.CommonRtcClient;
        this.CommonRongIMLib = window.GLOBAL.CommonRongIMLib;
        this.CommonRongCaller = window.GLOBAL.Caller;
        this.Events = window.GLOBAL.Events;

        this.getMessage();
      } else {
        setTimeout(this.initialRoom, 2000);
      }
    },
    getMessage() {
      const MESSAGES = this.CommonRongIMLib.Events.MESSAGES;

      this.CommonRongIMLib.addEventListener(MESSAGES, (event) => {
        // 处理消息队列
        event.messages.map((message) => {
          console.log(message);
          const memberId = message?.senderUserId;
          // 文本消息
          if (message.messageType === "RC:TxtMsg") {
            let msg = JSON.parse(message.content.content);
            let url = "";
            console.log(
              `-------------------------收到的${memberId}发送的消息${msg}---------`
            );
            // 判断是否是人员作业情况消息
            if (msg.mode == "afr") {
              url = "api/csp/afr/v1/afrRecord/newest/";
              this.getRealInfo(url, msg.mark);
            }
          }
        });
      });
    },
    getMsg() {
      this.rtmCommonChannel.on("MemberJoined", (memberId) => {
        // 你的代码：用户已加入频道。
        console.log("memberId", memberId);
      });
      this.rtmCommonChannel.on("ChannelMessage", (message, memberId) => {
        console.log("message", message);
        console.log("memberId", memberId);
        // 获取token,channel
        if (message.messageType === "TEXT") {
          if (message.text) {
            let msg = JSON.parse(message.text);
            let url = "";
            console.log(
              "---------------------------------------收到的msg--------------------------------------------"
            );
            console.log(msg);
            // 判断是否是人脸识别
            if (msg.mode == "afr") {
              url = "api/csp/afr/v1/afrRecord/newest/";
              this.getRealInfo(url, msg.mark);
            }
          }
        }
      });
    },
    // 获取实时推送数据
    getRealInfo(url, id) {
      let tmp = this.tableData;
      this.$axios({
        method: "get",
        url: `${url}${id}?type=1`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.realInfo = data.data;
          this.tableData = this.realInfo.concat(tmp).slice(0, 20);
          console.log(this.tableData);
          this.$forceUpdate();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    getList() {
      let url = "",
        params = "";
      if (this.type) {
        url = "api/csp/afr/v1/afrRecord/newest/0?type=1";
        params = "";
      } else {
        url = "api/csp/afr/v1/afrRecord/page";
        params = {
          startDate: this.options.startDate,
          endDate: this.options.endDate,
          name: this.options.name,
          idcard: this.options.idcard,
          createdName: this.options.createdName,
          status: this.options.status,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage,
        };
      }
      this.$axios({
        method: "get",
        url: url,
        params: params,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          if (this.type) {
            this.tableData = data.data;
          } else {
            this.tableData = data.data.rows;
            this.pageOptions.total = data.data.total;
          }
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 分页
    handleCurPageChange() {
      this.getList();
      // 分页之后，返回到顶部
      document.querySelector(".el-table__body-wrapper").scrollTop = 0;
    },
    typeChange() {
      this.getList();
      if (this.type) {
        this.rtmClient();
      }
    },
    deleteList(item) {
      this.currentItem = item;
      this.deleteDialogVisible = true;
    },
    handleDelete() {
      this.$axios({
        method: "delete",
        url: `api/csp/afr/v1/afrRecord/${this.currentItem.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.deleteDialogVisible = false;
          this.$message({
            type: "success",
            message: "删除成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    search() {
      this.searchDialogVisible = true;
    },
    handleSearch(params) {
      this.pageOptions.page = 1;
      this.options.startDate = params.startDate;
      this.options.endDate = params.endDate;
      this.options.name = params.name;
      this.options.idcard = params.idcard;
      this.options.createdName = params.createdName;
      this.options.status = params.status;
      this.getList();
      this.searchDialogVisible = false;
    },
    handleRowClick(row) {
      this.$router.push({
        path: `/aiRecognition/face/faceInfos`,
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/recognition.scss";
</style>
